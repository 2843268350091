var render = function () {
  var _vm$expenseInfo, _vm$expenseInfo2, _vm$expenseInfo3, _vm$expenseInfo4, _vm$expenseInfo5, _vm$expenseInfo5$depa, _vm$expenseInfo5$depa2, _vm$expenseInfo6, _vm$expenseInfo7, _vm$expenseInfo7$proj, _vm$expenseInfo7$proj2, _vm$expenseInfo7$proj3, _vm$expenseInfo7$proj4, _vm$expenseInfo8, _vm$accountInfo, _vm$accountInfo2, _vm$accountInfo3, _vm$accountInfo4, _vm$accountInfo5, _vm$accountInfo5$coun, _vm$accountInfo5$coun2, _vm$accountInfo6, _vm$accountInfo7, _vm$accountInfo8, _vm$accountInfo9, _vm$expenseInfo10, _vm$expenseInfo11, _vm$expenseInfo12, _vm$expenseInfo13, _vm$expenseInfo14, _vm$expenseInfo15, _vm$expenseInfo16, _vm$expenseInfo17, _vm$expenseInfo17$dep, _vm$expenseInfo17$dep2, _vm$expenseInfo18, _vm$expenseInfo19, _vm$expenseInfo19$pro, _vm$expenseInfo19$pro2, _vm$expenseInfo19$pro3, _vm$expenseInfo19$pro4, _vm$expenseInfo20, _vm$accountInfo10, _vm$accountInfo11, _vm$accountInfo12, _vm$accountInfo13, _vm$accountInfo14, _vm$accountInfo14$cou, _vm$accountInfo14$cou2, _vm$accountInfo15, _vm$accountInfo16, _vm$accountInfo17, _vm$accountInfo18, _vm$expenseInfo22, _vm$expenseInfo23;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-preview-wrapper"
  }, [_c('b-row', {
    staticClass: "invoice-preview"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "logo-wrapper"
  }, [_c('Logo')], 1), _c('div', {
    domProps: {
      "innerHTML": _vm._s(this.officeAddress)
    }
  }), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v(_vm._s(this.officeEmail))]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v(_vm._s(this.officeMobile))])]), _c('div', {
    staticClass: "mt-md-0 mt-2"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v(" Payslip "), _c('span', {
    staticClass: "invoice-number"
  }, [_vm._v("# " + _vm._s((_vm$expenseInfo = _vm.expenseInfo) === null || _vm$expenseInfo === void 0 ? void 0 : _vm$expenseInfo.payslip_no))]), _vm.expenseInfo ? [(_vm$expenseInfo2 = _vm.expenseInfo) !== null && _vm$expenseInfo2 !== void 0 && _vm$expenseInfo2.is_payed ? [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "pill": "",
      "variant": "light-success"
    }
  }, [_vm._v("Paid")])] : [_c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v("Due")])]] : _vm._e()], 2), _c('div', {
    staticClass: "invoice-date-wrapper"
  }, [_c('p', {
    staticClass: "invoice-date-title"
  }, [_vm._v("Date Issued:")]), _c('p', {
    staticClass: "invoice-date"
  }, [_vm._v(" " + _vm._s(_vm.formatDeadline((_vm$expenseInfo3 = _vm.expenseInfo) === null || _vm$expenseInfo3 === void 0 ? void 0 : _vm$expenseInfo3.payslip_created_date)) + " ")])]), _c('div', {
    staticClass: "invoice-date-wrapper"
  }, [_c('p', {
    staticClass: "invoice-date-title"
  }, [_vm._v("Due Date:")]), _c('p', {
    staticClass: "invoice-date"
  }, [_vm._v(" " + _vm._s(_vm.formatDeadline((_vm$expenseInfo4 = _vm.expenseInfo) === null || _vm$expenseInfo4 === void 0 ? void 0 : _vm$expenseInfo4.date)) + " ")])])])])]), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('b-row', {
    staticClass: "invoice-spacing"
  }, [_c('b-col', {
    staticClass: "p-0",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('h6', {}, [_vm._v("Department")]), _c('p', {
    staticClass: "mb-25"
  }, [_vm._v(" " + _vm._s((_vm$expenseInfo5 = _vm.expenseInfo) === null || _vm$expenseInfo5 === void 0 ? void 0 : (_vm$expenseInfo5$depa = _vm$expenseInfo5.department) === null || _vm$expenseInfo5$depa === void 0 ? void 0 : (_vm$expenseInfo5$depa2 = _vm$expenseInfo5$depa.data) === null || _vm$expenseInfo5$depa2 === void 0 ? void 0 : _vm$expenseInfo5$depa2.name) + " ")]), _c('div', {
    staticClass: "mt-2"
  }, [_c('h6', {}, [_vm._v("Project")]), (_vm$expenseInfo6 = _vm.expenseInfo) !== null && _vm$expenseInfo6 !== void 0 && _vm$expenseInfo6.project ? [_c('p', {
    staticClass: "mb-25"
  }, [_vm._v(" " + _vm._s((_vm$expenseInfo7 = _vm.expenseInfo) === null || _vm$expenseInfo7 === void 0 ? void 0 : (_vm$expenseInfo7$proj = _vm$expenseInfo7.project) === null || _vm$expenseInfo7$proj === void 0 ? void 0 : (_vm$expenseInfo7$proj2 = _vm$expenseInfo7$proj.data) === null || _vm$expenseInfo7$proj2 === void 0 ? void 0 : (_vm$expenseInfo7$proj3 = _vm$expenseInfo7$proj2.lead) === null || _vm$expenseInfo7$proj3 === void 0 ? void 0 : (_vm$expenseInfo7$proj4 = _vm$expenseInfo7$proj3.data) === null || _vm$expenseInfo7$proj4 === void 0 ? void 0 : _vm$expenseInfo7$proj4.title) + " ")])] : _vm._e()], 2)]), _c('b-col', {
    staticClass: "p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end",
    attrs: {
      "xl": "6",
      "cols": "12"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v("Payment Details:")]), _c('table', [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Total Due:")]), _c('td', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s((_vm$expenseInfo8 = _vm.expenseInfo) === null || _vm$expenseInfo8 === void 0 ? void 0 : _vm$expenseInfo8.amount))])])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Bank Name:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo = _vm.accountInfo) === null || _vm$accountInfo === void 0 ? void 0 : _vm$accountInfo.bank_name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Account Name:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo2 = _vm.accountInfo) === null || _vm$accountInfo2 === void 0 ? void 0 : _vm$accountInfo2.name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Account Number:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo3 = _vm.accountInfo) === null || _vm$accountInfo3 === void 0 ? void 0 : _vm$accountInfo3.account_number))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Routing Number:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo4 = _vm.accountInfo) === null || _vm$accountInfo4 === void 0 ? void 0 : _vm$accountInfo4.routing_number))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Country:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo5 = _vm.accountInfo) === null || _vm$accountInfo5 === void 0 ? void 0 : (_vm$accountInfo5$coun = _vm$accountInfo5.country) === null || _vm$accountInfo5$coun === void 0 ? void 0 : (_vm$accountInfo5$coun2 = _vm$accountInfo5$coun.data) === null || _vm$accountInfo5$coun2 === void 0 ? void 0 : _vm$accountInfo5$coun2.name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("IBAN:")]), _c('td', [_vm._v(" " + _vm._s((_vm$accountInfo6 = _vm.accountInfo) !== null && _vm$accountInfo6 !== void 0 && _vm$accountInfo6.iban_number ? (_vm$accountInfo7 = _vm.accountInfo) === null || _vm$accountInfo7 === void 0 ? void 0 : _vm$accountInfo7.iban_number : "N/A") + " ")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("SWIFT code:")]), _c('td', [_vm._v(" " + _vm._s((_vm$accountInfo8 = _vm.accountInfo) !== null && _vm$accountInfo8 !== void 0 && _vm$accountInfo8.swift_code ? (_vm$accountInfo9 = _vm.accountInfo) === null || _vm$accountInfo9 === void 0 ? void 0 : _vm$accountInfo9.swift_code : "N/A") + " ")])])])])])])], 1)], 1), _c('b-table-lite', {
    attrs: {
      "responsive": "",
      "items": _vm.payslipDescription,
      "fields": ['expenseTitle', 'amount', 'remarks']
    },
    scopedSlots: _vm._u([{
      key: "cell(expenseTitle)",
      fn: function fn(data) {
        return [_c('b-card-text', {
          staticClass: "font-weight-bold mb-25"
        }, [_vm._v(" " + _vm._s(data.item.expenseTitle) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(data) {
        var _vm$expenseInfo9;
        return [_c('b-card-text', {
          staticClass: "font-weight-bold mb-25"
        }, [_vm._v(" " + _vm._s("".concat((_vm$expenseInfo9 = _vm.expenseInfo) === null || _vm$expenseInfo9 === void 0 ? void 0 : _vm$expenseInfo9.currency, " ").concat(data.item.amount)) + " ")])];
      }
    }])
  }), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-md-0 mt-3",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-md": "1"
    }
  }), _c('b-col', {
    staticClass: "mt-md-6 d-flex justify-content-end",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "invoice-total-wrapper"
  }, [_c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Subtotal:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s((_vm$expenseInfo10 = _vm.expenseInfo) === null || _vm$expenseInfo10 === void 0 ? void 0 : _vm$expenseInfo10.amount) + " ")])]), _c('hr', {
    staticClass: "my-50"
  }), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Grand Total:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s((_vm$expenseInfo11 = _vm.expenseInfo) === null || _vm$expenseInfo11 === void 0 ? void 0 : _vm$expenseInfo11.amount) + " ")])])])])], 1)], 1), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note: ")]), _c('span', [_vm._v("This payslip was generated for this individual expense and authority has the right to cancel it!")])])], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": ""
    },
    on: {
      "click": _vm.printInvoice
    }
  }, [_vm._v(" Print ")]), _vm.$permissionAbility(_vm.PAYSLIP_PAYMENT_ACCESS, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.sidebar-invoice-add-payment",
      modifiers: {
        "sidebar-invoice-add-payment": true
      }
    }, {
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "success",
      "block": "",
      "disabled": (_vm$expenseInfo12 = _vm.expenseInfo) === null || _vm$expenseInfo12 === void 0 ? void 0 : _vm$expenseInfo12.is_payed
    }
  }, [_vm._v(" Make Payment ")])] : _vm._e()], 2)], 1)], 1), _c('payslip-sidebar-send-payslip'), _vm.expenseInfo ? [_c('payslip-sidebar-add-payment', {
    attrs: {
      "expenseInfo": _vm.expenseInfo
    },
    on: {
      "loadExpenseInfo": _vm.updateExpenseInfo
    }
  }), _c('div', {
    staticStyle: {
      "display": "none",
      "border": "none",
      "width": "100%",
      "font-family": "Arial, sans-serif"
    },
    attrs: {
      "id": "content"
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "0",
      "padding-bottom": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "flex-start",
      "margin-top": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "flex": "1",
      "margin-right": "20px"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c('Logo')], 1), _c('div', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    domProps: {
      "innerHTML": _vm._s(this.officeAddress)
    }
  }), _c('p', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._v(_vm._s(this.officeEmail))]), _c('p', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._v(_vm._s(this.officeMobile))])]), _c('div', {
    staticStyle: {
      "flex": "1",
      "margin-left": "20px",
      "text-align": "right"
    }
  }, [_c('h4', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(" Payslip "), _c('span', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("# " + _vm._s((_vm$expenseInfo13 = _vm.expenseInfo) === null || _vm$expenseInfo13 === void 0 ? void 0 : _vm$expenseInfo13.payslip_no))]), _vm.expenseInfo ? [(_vm$expenseInfo14 = _vm.expenseInfo) !== null && _vm$expenseInfo14 !== void 0 && _vm$expenseInfo14.is_payed ? [_c('span', {
    staticStyle: {
      "background-color": "lightgreen",
      "padding": "2px 5px",
      "border-radius": "5px",
      "margin-left": "5px"
    }
  }, [_vm._v("Paid")])] : [_c('span', {
    staticStyle: {
      "background-color": "lightcoral",
      "padding": "2px 5px",
      "border-radius": "5px",
      "margin-left": "5px"
    }
  }, [_vm._v("Due")])]] : _vm._e()], 2), _c('div', {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Date Issued:")]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s(_vm.formatDeadline((_vm$expenseInfo15 = _vm.expenseInfo) === null || _vm$expenseInfo15 === void 0 ? void 0 : _vm$expenseInfo15.payslip_created_date)))])]), _c('div', {
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Due Date:")]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s(_vm.formatDeadline((_vm$expenseInfo16 = _vm.expenseInfo) === null || _vm$expenseInfo16 === void 0 ? void 0 : _vm$expenseInfo16.date)))])])])])]), _c('hr', {
    staticStyle: {
      "margin": "20px 0"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "0",
      "padding-top": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "margin": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "flex": "1",
      "padding": "0"
    }
  }, [_c('h6', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Department")]), _c('p', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s((_vm$expenseInfo17 = _vm.expenseInfo) === null || _vm$expenseInfo17 === void 0 ? void 0 : (_vm$expenseInfo17$dep = _vm$expenseInfo17.department) === null || _vm$expenseInfo17$dep === void 0 ? void 0 : (_vm$expenseInfo17$dep2 = _vm$expenseInfo17$dep.data) === null || _vm$expenseInfo17$dep2 === void 0 ? void 0 : _vm$expenseInfo17$dep2.name))]), _c('div', {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c('h6', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Project")]), (_vm$expenseInfo18 = _vm.expenseInfo) !== null && _vm$expenseInfo18 !== void 0 && _vm$expenseInfo18.project ? [_c('p', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s((_vm$expenseInfo19 = _vm.expenseInfo) === null || _vm$expenseInfo19 === void 0 ? void 0 : (_vm$expenseInfo19$pro = _vm$expenseInfo19.project) === null || _vm$expenseInfo19$pro === void 0 ? void 0 : (_vm$expenseInfo19$pro2 = _vm$expenseInfo19$pro.data) === null || _vm$expenseInfo19$pro2 === void 0 ? void 0 : (_vm$expenseInfo19$pro3 = _vm$expenseInfo19$pro2.lead) === null || _vm$expenseInfo19$pro3 === void 0 ? void 0 : (_vm$expenseInfo19$pro4 = _vm$expenseInfo19$pro3.data) === null || _vm$expenseInfo19$pro4 === void 0 ? void 0 : _vm$expenseInfo19$pro4.title))])] : _vm._e()], 2)]), _c('div', {
    staticStyle: {
      "flex": "1",
      "padding": "0",
      "margin-top": "10px",
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('div', [_c('h6', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("Payment Details:")]), _c('table', {
    staticStyle: {
      "width": "100%",
      "border-collapse": "collapse"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("Total Due:")]), _c('td', [_c('span', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s((_vm$expenseInfo20 = _vm.expenseInfo) === null || _vm$expenseInfo20 === void 0 ? void 0 : _vm$expenseInfo20.amount))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("Bank Name:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo10 = _vm.accountInfo) === null || _vm$accountInfo10 === void 0 ? void 0 : _vm$accountInfo10.bank_name))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("Account Name:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo11 = _vm.accountInfo) === null || _vm$accountInfo11 === void 0 ? void 0 : _vm$accountInfo11.name))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("Account Number:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo12 = _vm.accountInfo) === null || _vm$accountInfo12 === void 0 ? void 0 : _vm$accountInfo12.account_number))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("Routing Number:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo13 = _vm.accountInfo) === null || _vm$accountInfo13 === void 0 ? void 0 : _vm$accountInfo13.routing_number))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("Country:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo14 = _vm.accountInfo) === null || _vm$accountInfo14 === void 0 ? void 0 : (_vm$accountInfo14$cou = _vm$accountInfo14.country) === null || _vm$accountInfo14$cou === void 0 ? void 0 : (_vm$accountInfo14$cou2 = _vm$accountInfo14$cou.data) === null || _vm$accountInfo14$cou2 === void 0 ? void 0 : _vm$accountInfo14$cou2.name))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("IBAN:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo15 = _vm.accountInfo) !== null && _vm$accountInfo15 !== void 0 && _vm$accountInfo15.iban_number ? (_vm$accountInfo16 = _vm.accountInfo) === null || _vm$accountInfo16 === void 0 ? void 0 : _vm$accountInfo16.iban_number : "N/A"))])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding-right": "5px"
    }
  }, [_vm._v("SWIFT code:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo17 = _vm.accountInfo) !== null && _vm$accountInfo17 !== void 0 && _vm$accountInfo17.swift_code ? (_vm$accountInfo18 = _vm.accountInfo) === null || _vm$accountInfo18 === void 0 ? void 0 : _vm$accountInfo18.swift_code : "N/A"))])])])])])])])]), _c('table', {
    staticStyle: {
      "width": "100%",
      "margin-top": "20px",
      "border-collapse": "collapse"
    }
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.payslipDescription, function (item) {
    var _vm$expenseInfo21;
    return _c('tr', {
      key: item.expenseTitle
    }, [_c('td', {
      staticStyle: {
        "text-align": "center",
        "padding": "10px",
        "vertical-align": "middle"
      }
    }, [_vm._v(_vm._s(item.expenseTitle))]), _c('td', {
      staticStyle: {
        "text-align": "center",
        "padding": "10px",
        "vertical-align": "middle"
      }
    }, [_vm._v(_vm._s("".concat((_vm$expenseInfo21 = _vm.expenseInfo) === null || _vm$expenseInfo21 === void 0 ? void 0 : _vm$expenseInfo21.currency, " ").concat(item.amount)))]), _c('td', {
      staticStyle: {
        "text-align": "center",
        "padding": "10px",
        "vertical-align": "middle"
      }
    }, [_vm._v(_vm._s(item.remarks))])]);
  }), 0)]), _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('div', [_c('p', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("Subtotal:")]), _c('p', {
    staticStyle: {
      "margin-bottom": "10px",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s((_vm$expenseInfo22 = _vm.expenseInfo) === null || _vm$expenseInfo22 === void 0 ? void 0 : _vm$expenseInfo22.amount))])])]), _c('hr', {
    staticStyle: {
      "margin": "10px 0"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('div', [_c('p', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("Grand Total:")]), _c('p', {
    staticStyle: {
      "margin-bottom": "10px",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s((_vm$expenseInfo23 = _vm.expenseInfo) === null || _vm$expenseInfo23 === void 0 ? void 0 : _vm$expenseInfo23.amount))])])])]), _vm._m(1)])] : _vm._e()], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "40%",
      "text-align": "center",
      "border-bottom": "1px solid #ddd",
      "padding": "10px"
    }
  }, [_vm._v("Expense Title")]), _c('th', {
    staticStyle: {
      "width": "30%",
      "text-align": "center",
      "border-bottom": "1px solid #ddd",
      "padding": "10px"
    }
  }, [_vm._v("Amount")]), _c('th', {
    staticStyle: {
      "width": "30%",
      "text-align": "center",
      "border-bottom": "1px solid #ddd",
      "padding": "10px"
    }
  }, [_vm._v("Remarks")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('span', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Note: ")]), _c('span', [_vm._v("This payslip was generated for this individual expense and authority has the right to cancel it!")])]);
}]

export { render, staticRenderFns }